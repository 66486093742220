body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.5s ease;
  background: #08021C;
  color: white
}

code {
  font-family: 'Montserrat', sans-serif;
}
html {
  font-family: 'Montserrat', sans-serif;
}

/* CONTAINER */
.container {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  
}

/* SCROLL BAR */

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f16e;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f1f1f16e;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f1f1f16e;
}
